<template lang="pug">
	el-table(:data="control_group.working_hours_list" border="" fit="" highlight-current-row="" size="mini")
		el-table-column(align="center" label="Day"  width="120px" fixed="left")
			template(slot-scope="scope")
				| {{ scope.row.label }}
		el-table-column(align="center" label="Toggle" width="150px")
			template(slot-scope="scope")
				el-switch( :value="scope.row.flag" active-text="On" inactive-text="Off" )
		el-table-column(align="center" label="00:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[0]" )
		el-table-column(align="center" label="01:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[1]" )
		el-table-column(align="center" label="02:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[2]" )
		el-table-column(align="center" label="03:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[3]" )
		el-table-column(align="center" label="04:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[4]" )
		el-table-column(align="center" label="05:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[5]" )
		el-table-column(align="center" label="06:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[6]" )
		el-table-column(align="center" label="07:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[7]" )
		el-table-column(align="center" label="08:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[8]" )
		el-table-column(align="center" label="09:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[9]" )
		el-table-column(align="center" label="10:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[10]" )
		el-table-column(align="center" label="11:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[11]" )
		el-table-column(align="center" label="12:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[12]" )
		el-table-column(align="center" label="13:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[13]" )
		el-table-column(align="center" label="14:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[14]" )
		el-table-column(align="center" label="15:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[15]" )
		el-table-column(align="center" label="16:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[16]" )
		el-table-column(align="center" label="17:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[17]" )
		el-table-column(align="center" label="18:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[18]" )
		el-table-column(align="center" label="19:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[19]" )
		el-table-column(align="center" label="20:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[20]" )
		el-table-column(align="center" label="21:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[21]" )
		el-table-column(align="center" label="22:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[22]" )
		el-table-column(align="center" label="23:00" min-width="50px")
			template(slot-scope="scope")
				el-checkbox(:disabled="!scope.row.flag" :value="scope.row.hours[23]" )
</template>


<style lang="scss">

</style>


<script>
export default {
	name: 'working-hours-grid',
	data() {
		return {

		}
	},
	props: {
		control_group: {
			type: Object,
			required: true
		}
	}
}
</script>