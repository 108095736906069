<template lang="pug">
	#brand-form-root
		#brand-form-body
			.widget
				el-card.box-card
					el-row(slot="header")
						el-col.text-left(:span="24")
							.d-flex.justify-content-between
								h3
									b Brands - {{this.form.type}} new entity
					//pre {{this.options.users}}
					el-form(:form="form" :model="form" ref="form" :rules="rules" )
						el-row.text-left()
							h5 Brand Details
							p Enter the brand name and the url. You can also assign the brand to the relevant sales agent.
						el-row(:gutter="12")
							el-col(:xs="24", :sm="8"  :xl="4")
								el-form-item(label="Name" prop="name")
									el-input(v-model="form.name" placeholder="Brand name" @blur="copyToGlobal")
							el-col(:xs="24", :sm="8" :xl="4")
								el-form-item(prop="global_name")
									template(slot="label")
										span.label-color-override Global Name&nbsp;
											small.text-success (uppercase, used for budgets)
									el-input(v-model="form.global_name" placeholder="Brand name")
							el-col(:xs="24", :sm="8"  :xl="4")
								el-form-item(label="Assignee ID" prop="uuid")
									el-select.w-100(v-model="form.user_id" placeholder="Select a user" filterable="")
										el-option(label="- None, Select an assignee -" :value="''")
										el-option(v-for="item in options.users" :key="item.dx" :label="`${item.t} - (${item.n}${item.e ? (`, ${item.e})`) : ')' }`" :value="item.v")
							el-col(:xs="24", :sm="24" :xl="12")
								el-form-item(label="Brand URL" prop="url")
									el-input( v-model="form.url" placeholder="Brand URL")
							el-col(:span="24")
								el-form-item(label="Comments" prop="comments")
									el-input(type="textarea" :rows="5" v-model="form.comments" placeholder="Lorem Ipsum Dolor Sit Amet" :maxlength="3000" show-word-limit="")

						el-divider

						el-row.mt-3.text-left()
							h5 Control Group
							p Assign the brand to a control group. The control group is a set of rules that will be applied to the leads that are registered under this brand. It controls the working hours and the GEOs that the brand will receive leads from. If you don't assign a control group, the brand will receive leads from all GEOs and all working hours.
						el-row(:gutter="12")
							el-col(:xs="12" :sm="6" )
								el-form-item(label="Toggle working hours" position="left")
									el-switch(v-model="form.working_hours_flag" active-text="On" inactive-text="Off" )



						el-row.text-left(:gutter="24" v-if="form.working_hours_flag")
							el-col(:xs="24" :md="12" :lg="8" )
								el-form-item.text-left(label="Choose Control Group" position="left")
									.d-flex.justify-content-between
										el-select.w-100( v-model="form.control_group_id" placeholder="Select a control group" filterable="" multiple="" collapse-tags="")
											el-option(label="- None, Select a control group -" :value="null")
											el-option(v-for="o in options.control_groups" :key="o.dx" :label="o.v + ' - ' + o.t + `${o.n?(' ('+o.n+')'):''}`" :value="o.v")

										el-tooltip(content="Show Control Group")
											el-button.ml-2.append-button(type="primary" icon="el-icon-search" @click="handleCGModal()")

							//el-col(:span="24")
								working-hours-grid(v-if="form.working_hours_flag && form.control_group_id" :control_group="form.control_group")
						//el-row.mt-4.text-left( v-if="form.working_hours_flag && form.control_group_id")
							el-col(:span="24")
								h5 Affected Geo Locations
								p.text-left The following Geo Locations are assigned to this control group:&nbsp;
									span.text-success() {{ form.control_group.mapped.join(', ') }}&nbsp;
						//	h5 Working Hours
						//	p Set the working hours and toggle it on or off. If a lead is registered not in the working hours, from his perspective he will be registered on a normal basis (will be redirected to the "thankyou page"), but, will be saved as a failed lead with the failed reason of "out of working hours" and won't send it to the brand.
						//	p The working hours are set for each day of the week. If you toggle of a day, the brand won't receive leads on that day.
						//	p.text-success
						//		i *Note: The timezone of the working hours is the server's timezone which is UTC+3
						//el-row(:gutter="12")
						//	el-col(:xs="12" :sm="6" )
						//		el-form-item(label="Toggle working hours" position="left")
						//			el-switch(v-model="form.working_hours_flag" active-text="On" inactive-text="Off" )
						//
						//el-row(:gutter="12" v-if="form.working_hours_flag")
						//	el-col(:span="24")
						//		el-row.mt-2(:gutter="12" v-for="row of form.working_hours_list" :key="row.label")
						//			el-col.text-left(:xs="12" :sm="6" :md="4" :xl="2")
						//				label.label-color-override {{row.label}}
						//			el-col(:xs="12" :sm="6" :md="3" :xl="2")
						//				el-switch.w-100(v-model="row.flag" active-text="On" inactive-text="Off" )
						//
						//			el-col(v-if="row.flag" :xs="12" :sm="6" :md="4")
						//				el-time-select.w-100(v-model="row.start" placeholder="Start time" size="mini" :picker-options="timePickerOptions.full")
						//			el-col(v-if="row.flag" :xs="12" :sm="6" :md="4")
						//				el-time-select.w-100(v-model="row.end" placeholder="End time" size="mini" :picker-options="timePickerOptions.full")

						el-divider
						el-row.text-left()
							h5 Brand Logo
							p Upload a brand logo or link to an image URL
						el-row(:gutter="12")
							el-col(:xs="24" :sm="12")
								el-form-item(label="Link to brand logo" :error="errors.img_src.s ? errors.img_src.m : ''" v-bind:class="{'is-invalid': errors.img_src.s}")
									el-input(v-model="form.img_src" placeholder="URL link to the image logo")
							el-col(:xs="24" :sm="12")
								el-form-item(label="Upload logo")
									el-upload.upload-demo(
										drag="" :action="`${api_path}file/upload`" w thumbnail-mode="" :disabled="list.length >= 1" v-bind:class="{'disabled-field': list.length >= 5}" :on-success="handleSuccess" :file-list="fileList" multiple="" :headers="auth" :limit="3" :before-upload="beforeUpload")
										i.el-icon-upload
										.el-upload__text
											| Drop file here or
											em click to upload
										.el-upload__tip(slot='tip') jpg/png files with a size less than 4MB - Max 5 files
						el-row(:gutter="12" justify="between")
							el-button(@click="submit" type="primary") Save
							el-button(type="danger" @click="$router.push({name: 'iframe-list'})") Cancel
		#brand-form-dialog
			el-dialog(:visible.sync="showControlGroupModal" width="75%" center="" @closed="showControlGroupModal = false" title="Assigned Control Groups" )
				//pre {{form.control_groups}}
				el-empty(v-if="currentControlGroups.length === 0" description="No control groups assigned to this brand")
				el-card.mt-3(v-if="currentControlGroups")
					el-row.mt-3(:gutter="12")
						el-badge(value="MERGED" type="primary" )
							h5 Merged Control Group - Details
					p <b>Decription</b>: This is a merged version of the control group that is assigned to the brand. The days and the open hours are merged and the registration service will use this merged version to determine if the lead is registered in the working hours or not.
					el-row.mt-4.text-left()
						el-col(:span="24")
							working-hours-grid( :control_group="mergedControlGroup")
					el-row.mt-4.text-left()
						el-col(:span="24")
							p.text-success
								i
									b Note:&nbsp;
									p The registration service checks the hours and geos seperatly
				el-card.mt-3(v-for="(cg,idx) of currentControlGroups" :key="cg.id")
					//el-divider(v-if="currentControlGroups.length > 1 && idx > 0")
					el-row.mt-3(:gutter="12")
						el-badge(value="ON" :hidden="!cg.working_hours_flag")
							h5 Control Group Details - {{'#' + cg.id}} {{cg.name}}&nbsp;
								small.text-info ({{cg.notes}})
						p <b>Decription</b>: {{cg.comments}}
					el-row.mt-4.text-left()
						el-col(:span="24")
							working-hours-grid( :control_group="cg")
					el-row.mt-4.text-left()
						el-col(:span="24")
							h5 Affected Geo Locations
							p.text-left The following Geo Locations are assigned to this control group:&nbsp;
								span.text-success() {{ cg.mapped.join(', ') }}&nbsp;

</template>


<style lang="scss">

</style>

<style scoped>
.select-with-button {
	display: flex;
	align-items: center;
}
.append-button {
	margin-left: 4px; /* Adjust spacing between select and button */
}
</style>


<script lang="js">
import {notEmpty, validateAlphaNumeric, validateAlphaNumericAndExists} from "@/utils/validate";
import {daysOfWeek, workingDaysOfWeek, timePickerOptions, $controlGroupTemplate} from "@/struct";

const VALID = [
	'image/png',
	'image/bmp',
	'image/jpg',
	'image/jpeg',
	'image/gif',
	'application/pdf',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation'
];

import validator from 'validator';
import Vue from 'vue';
import WorkingHoursGrid from "@/components/working-hours-grid.vue";

let clearBeforeSend = (data) => {
	let body = {...data};
	delete body.type;
	delete body.control_group;
	return body;
};

export default {
	name: 'brand-form',
	components: {WorkingHoursGrid},
	async beforeRouteEnter(to, from, next) {
		if (to.name === 'iframe-add-brand') {
			const options = await Vue.apix.sendHttpRequest('GET', 'components/options', {filters: '30b65663-69af-4020-b7bf-1daefd801ae6,6e1430cf-6c24-4da7-aeaf-a63579441e45'});
			next(vm => {
				vm.options.users = options.users;
				vm.options.control_groups = options.control_groups;
			});
		} else if (to.name === 'iframe-edit-brand') {
			try {
				const options = await Vue.apix.sendHttpRequest('GET', 'components/options', {filters: '30b65663-69af-4020-b7bf-1daefd801ae6,6e1430cf-6c24-4da7-aeaf-a63579441e45'});
				let result = await Vue.apix.sendHttpRequest('GET', 'brand/get', {id: to.params.id});
				const currentGroups = await Vue.apix.sendHttpRequest('POST', 'control-group/get-multi', {list: result.control_groups.map(cg => cg.id)});

				next(vm => {
					vm.entity_id = 			to.params.id;
					vm.originalName = 	result.name;
					vm.form = {
						type: 							'edit',
						name: 							result.name,
						global_name: 				result.global_name,
						url: 								result.url,
						allow_duplicates: 	result.allow_duplicates,
						img_src: 						result.img_src,
						user_id: 						result.user_id ? result.user.uuid : '',
						// working_hours_list: result.working_hours_list,
						working_hours_flag: result.working_hours_flag,
						comments: 					result.comments || '',
						control_group_id:   result.control_groups.map(cg => cg.id),
					};



					vm.currentControlGroups = currentGroups;
					vm.options.users = options.users;
					vm.options.control_groups = options.control_groups;
					vm.rules.name.original = result.name;
					vm.rules.name.action = 'edit';
				});
			} catch (e) {
				Vue.router.push({name: 'brand-list'})
			}
		}
	},
	data() {
		return {
			originalName: '',
			daysOfWeek,
			timePickerOptions,
			showControlGroupModal: false,
			currentControlGroups: [],
			form: {
				type: 'add',
				name: '',
				global_name: '',
				url: '',
				img_src: '',
				user_id: '',
				allow_duplicates: false,
				working_hours_flag: false,
				// working_hours_list: workingDaysOfWeek,
				comments: '',
				control_group_id: null,
				control_group: JSON.parse(JSON.stringify($controlGroupTemplate)),
			},
			rules: {
				name: {required: true, trigger: 'blur', validator: validateAlphaNumericAndExists, topic: 'brand', app: this,},
				global_name: {required: true, trigger: 'blur', validator: validateAlphaNumeric, topic: 'brand', app: this,},
				url: {required: true, trigger: 'blur', validator: notEmpty},
				img_src: {required: false, trigger: 'blur'},
				comments: {required: false, trigger: 'blur'}
			},
			errors: {
				img_src: {s: false, m: ''}
			},
			busy: false,
			valid: VALID,
			fileList: [],
			list: [],
			options: {
				users: []
			}
		}
	},
	computed: {
		api_path() {
			return process.env.VUE_APP_BASE_URL
		},
		auth() {
			return { Authorization: `Bearer ${this.$store.state.AUTH_TOKEN}` }
		},
		mergedControlGroup() {
			let template = JSON.parse(JSON.stringify($controlGroupTemplate));
			this.currentControlGroups.forEach((cg,i) => {
				cg.working_hours_list.forEach((whl,j) => {
					Object.keys(whl.hours).forEach(h => {
						if(whl.flag) template.working_hours_list[j].flag = whl.flag;
						if(whl.hours[h]) template.working_hours_list[j].hours[h] = whl.hours[h];
					})
				});
			});
			return template;
		},
	},

	methods: {
		handleCGModal() {

			this.$apix.sendHttpRequest('POST', 'control-group/get-multi', {list: this.form.control_group_id})
				.then(res => {
					this.showControlGroupModal = true;
					this.currentControlGroups = res;
				})
		},
		async submit() {
			this.$refs.form.validate(async valid => {
				if (!valid) return this.$message.error({title: 'Validation Error', message: 'The input form values are invalid. Please, refer to the errors in red'});
				try {
					if (this.form.type === 'add') {
						let result = await Vue.apix.sendHttpRequest('POST', `brand/create`, {data: clearBeforeSend(this.form)})
						if (result.id) this.$router.push({name: 'iframe-list'})
					} else {
						let result = await Vue.apix.sendHttpRequest('PUT', `brand/${'update/' + this.entity_id}`, {data: clearBeforeSend(this.form)})
						this.$router.push({name: 'iframe-list'})
					}
				} catch (e) {
					console.error(e);
				}
			})
		},

		handleSuccess() {
			// TODO - assign the link to the brand
		},
		beforeUpload(file) {
			if (!this.valid.includes(file.type)) {
				this.$message.error('Document file must be a picture, PDF or an office document!');
				return false
			}

			if (file.size / 1024 / 1024 > 4) {
				this.$message.error('Document file size can not exceed 4MB!');
				return false
			}
		},
		downloadFile(name) {
			window.open(`${this.serverPath}${name}`)
		},
		copyToGlobal() {
			if(!this.form.global_name) this.form.global_name = this.form.name.toUpperCase();
		}
	}
}
</script>
